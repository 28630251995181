/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-10-11T12:31:53.159Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.4.10000-1582875';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
